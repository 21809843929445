import React, { Component } from 'react';
import styles from './Logo.module.css';
import logo from '../images/logo.png';

class Icon extends Component {
  render() {
    return (
      <div className={styles.container}>
        <img src={logo} className={styles.logo} alt="A sharp, bold logo comprised of the letters CNK" />
      </div>
    );
  }
}

export default Icon;
