import React, { Component } from 'react';
import styles from './Icon.module.css';
import rssIcon from '../images/rss-icon.svg';
import twitterIcon from '../images/twitter-icon.svg';
import githubIcon from '../images/github-icon.svg';
import lastfmIcon from '../images/lastfm-icon.svg';

class Icon extends Component {
  getIcon() {
    const { name } = this.props
    if (name === "rss") return rssIcon
    if (name === "twitter") return twitterIcon
    if (name === "github") return githubIcon
    if (name === "lastfm") return lastfmIcon
    return false
  }

  render() {
    const { title } = this.props

    return (
      <div className={styles.container}>
        <img src={this.getIcon()} alt={title} className={styles.icon} />
      </div>
    );
  }
}

export default Icon;
