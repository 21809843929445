import React, { Component } from 'react';
import styles from './Accent.module.css';

class Accent extends Component {
  render() {
    return (
      <div className={styles.container}>
        <svg width="301" height="201">
          <polygon points="0,0 0,200 300,000" className={styles.primary} />
          <polygon points="0,197 0,201 301,0 295,0" className={styles.secondary} />
        </svg>
      </div>
    );
  }
}

export default Accent;
