import React, { Component } from 'react';
import styles from './Application.module.css';
import Accent from './components/Accent.js';
import Logo from './components/Logo.js';
import SocialLink from './components/SocialLink.js';
class Application extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.accent}>
          <Accent />
        </div>
        <div className={styles.content}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.description}>
            <h1>
              <span role="img" aria-label="Waving Hand">👋</span> G'day, I'm <strong>Chris Nankervis</strong>, a <strong>Software Engineer</strong> based in <strong>Melbourne, Australia</strong>.
            </h1>
            <h2>
              I'm currently working at <a href="https://practices.hotdoc.com.au/about" title="HotDoc" target="_blank" rel="noopener noreferrer">HotDoc</a> with <a href="https://rubyonrails.org" title="Ruby on Rails" target="_blank" rel="noopener noreferrer">Ruby on Rails</a>.
            </h2>
            <p>
              I'm honest, friendly, passionate and try my best to maintain a positive attitude.
            </p>
            <p>
              I get a real kick out of solving unique and challenging problems with scalable, maintainable and effective solutions.
            </p>
            <p>
              Drop me a line if you want to chat, I'm all ears.
            </p>
          </div>
          <div className={styles.socialLinks}>
            <SocialLink
              title="Twitter"
              link="https://twitter.com/chrisnankervis"
              iconName="twitter"
            />
            <SocialLink
              title="GitHub"
              link="https://github.com/chrisnankervis"
              iconName="github"
            />
            <SocialLink
              title="Last.fm"
              link="https://last.fm/user/chrisnankervis"
              iconName="lastfm"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Application;
