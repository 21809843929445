import React, { Component } from 'react';
import styles from './SocialLink.module.css';
import Icon from './Icon.js';

class SocialLink extends Component {
  render() {
    const { title, link, iconName } = this.props

    return (
      <div className={styles.container}>
        <a href={link} title={title} target="_blank" rel="noopener noreferrer">
          <Icon name={iconName} title={title} />
          {title}
        </a>
      </div>
    );
  }
}

export default SocialLink;
